import React from 'react';
import { css } from '@emotion/core';
import { Layout, Main } from 'src/components/layout';
import { RootWrapper } from 'src/components/RootWrapper';
import { IndexHeader, IndexFooter } from 'src/components/pages/index';
import { TextContextProvider } from 'src/components/TextContext';
import esTextCommon from '../../text/es/common.yaml';
import esTextContacto from '../../text/es/contacto.yaml';
import enTextCommon from '../../text/en/common.yaml';
import enTextContacto from '../../text/en/contacto.yaml';
import { ContactContent } from 'src/components/pages/contacto/ContactContent';

const esText = { ...esTextCommon, ...esTextContacto };
const enText = { ...enTextCommon, ...enTextContacto };
const text = {
  es: esText,
  en: enText,
};

const QuienesSomosPage = ({ location }) => {
  return (
    <RootWrapper>
      <TextContextProvider text={text}>
        <Layout>
          <IndexHeader backgroundVisibility={1} location={location} />
          <Main
            css={css`
              margin-top: 4rem;
            `}
          >
            <ContactContent />
          </Main>
          <IndexFooter />
        </Layout>
      </TextContextProvider>
    </RootWrapper>
  );
};

export default QuienesSomosPage;
