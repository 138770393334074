import React, { useState } from 'react';

import { css } from '@emotion/core';
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { MAIN_COLOR } from 'src/consts';
import { InputAdornment } from '@material-ui/core/';
import { PermIdentity, EmailOutlined, EditOutlined } from '@material-ui/icons/';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useText } from 'src/components/TextContext';
import axios from 'axios';

const schema = yup.object().shape({
  name: yup.string().required(),
  mail: yup.string().required().email(),
  message: yup.string().required(),
});

export const ContactContent: React.FC = (props) => {
  const t = useText();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setOpen(false);

  const onSubmit = async (values) => {
    if (isLoading) return;
    setIsLoading(true);
    
    const url = `http://${window.location.hostname}/api/contact`;
    // const url = `http://lasnipas.com/api/contact`;
    // const url = `http://localhost/api/contact/`;
    const response = await axios.get(url, {
      params: values,
    });
    setOpen(true);
    setIsLoading(false);
  };
  return (
    <div
      css={css`
        display: flex;
        margin-top: 5rem;
      `}
    >
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent
          css={css`
            width: 20rem;
            height: 5rem;
            display: flex;
          `}
        >
          <Typography
            variant="h5"
            css={css`
              margin: auto;
            `}
          >
            {t.sentDialog.successMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t.sentDialog.closeButton}</Button>
        </DialogActions>
      </Dialog>
      <form
        onSubmit={handleSubmit(onSubmit)}
        css={css`
          margin: auto;
          width: min(100%, 60rem);
          display: flex;
          background-color: rgba(0, 0, 0, 0.9);
          padding: 2rem;
          flex-direction: column;
          row-gap: 1rem;
          border-radius: 20px;
        `}
        {...props}
      >
        <TextField
          label={t.contactForm.nameField}
          variant="filled"
          name="name"
          inputRef={register}
          error={Boolean(errors.name?.message)}
          helperText={errors.name?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentity />
              </InputAdornment>
            ),
          }}
          css={css`
            background-color: white;
            grid-column: 1;
            border-radius: 10px;
          `}
        />
        <TextField
          label={t.contactForm.mailField}
          variant="filled"
          name="mail"
          inputRef={register}
          error={Boolean(errors.mail?.message)}
          helperText={errors.mail?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
          css={css`
            margin-top: 1rem;
            background-color: white;
            grid-column: 1;
            grid-row: 2;
            border-radius: 10px;
          `}
        />
        <TextField
          label={t.contactForm.messageField}
          variant="filled"
          name="message"
          inputRef={register}
          error={Boolean(errors.message?.message)}
          helperText={errors.message?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EditOutlined
                  css={css`
                    margin-bottom: 5rem;
                  `}
                />
              </InputAdornment>
            ),
          }}
          multiline
          rows={4}
          css={css`
            margin-top: 1rem;
            background-color: white;
            grid-column: 2;
            grid-row: 1 / 3;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          `}
        />

        <Button
          type="submit"
          variant="contained"
          disabled={isLoading ? true : false}
          css={css`
            background-color: ${MAIN_COLOR};
          `}
        >
          {isLoading ? <CircularProgress /> : t.contactForm.submitButton}
        </Button>
      </form>
    </div>
  );
};

export default ContactContent;
